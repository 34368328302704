import './list.scss';
import dataApi from '@/api/manage/area.js';
import confApi from '@/api/system/index.js';

export default {
  name: 'area-list',
  components: {},
  data () {
    return {
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      tableData: [],
      tbleOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        ...this.$store.getters.tableConfig,
        column: [
          {
            type: 'tree',
            label: '上级区域',
            prop: 'parent_id',
            span: 24,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            dicUrl: `${this.$baseUrl}general/get_area_tree`,
            dicMethod: 'post',
            dicFlag: true,
            multiple: false,
            dataType: 'number',
            dicQuery: {}
          },
          {
            label: '区域名称',
            prop: 'name',
            type: 'input',
            span: 24,
            required: true
          },
          {
            type: 'radio',
            label: '级别',
            span: 12,
            value: 1,
            dicData: [
              {
                label: '国家',
                value: 0
              },
              {
                label: '省/直辖市',
                value: 1
              },
              {
                label: '地级市',
                value: 2
              },
              {
                label: '区县',
                value: 3
              },
              {
                label: '镇/街道',
                value: 4
              }

            ],
            display: true,
            prop: 'level',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true
          },
          {
            label: '简称',
            prop: 'abbr',
            type: 'input',
            span: 12,
            required: true
          },
          {
            type: 'radio',
            label: '状态',
            span: 12,
            value: 1,
            dicData: [
              {
                label: '禁用',
                value: 0
              },
              {
                label: '启用',
                value: 1
              }
            ],
            display: true,
            prop: 'status',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true
          },
          { // 排序
            type: 'number',
            value: 0,
            label: '排序',
            span: 12,
            display: true,
            prop: 'sorting'
          },
          {
            // 创建者 create_by
            label: '创建者',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_user'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            // 更新者 update_by
            label: '更新者',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_user'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          }
        ]
      },
      searchData: {},
      dialogVisible: false,
      hotFormOp: {
        column: [
          {
            label: '热点区域',
            type: 'tree',
            span: 24,
            display: true,
            dicFlag: true,
            prop: 'hot_area',
            props: {
              label: 'label',
              value: 'value'
            },
            filterable: true,
            multiple: true,
            dicUrl: `${this.$baseUrl}general/get_area_tree`,
            dicQuery: {},
            dicMethod: 'post',
            dataType: 'number'
          }
        ],
        labelPosition: 'left',
        labelSuffix: '：',
        labelWidth: 100,
        gutter: 0,
        menuSpan: 24,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: true,
        emptyText: '清空',
        menuPosition: 'center'
      },
      hotFormDa: {}
    };
  },
  computed: {},
  mounted () {},
  filters: {},
  methods: {
    // 分页
    onLoad (page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange (params, done) {
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset () {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    handleSubmit (form, done, loading) {
      console.log('form', form, loading);
      dataApi.add.r(form).then(res => {
        console.log(res);
        done(form);
        loading();
        this.getList();
      });
      done();
    },
    // 删除
    rowDel (form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form.id }).then(res => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 修改
    rowUpdate (form, index, done, loading) {
      console.log('index', form, index);
      dataApi.update.r(form).then(res => {
        console.log(res);
        done(form);
        this.getList();
        loading();
      });
      done();
    },
    hotDialog () {
      this.getHotArea();
      this.dialogVisible = true;
    },
    submitHotForm (form, done) {
      const field = JSON.stringify({
        hot_area: form.hot_area
      });
      const postData = {
        scenario: 'web_hot_area',
        field: field
      };
      confApi.confPost.r(postData).then(() => {
        done();
        this.getHotArea();
        this.dialogVisible = false;
        this.$message.success('热点区域更新成功');
      });
    },
    getHotArea () {
      // 获取热点区域
      const postData = {
        scenario: 'web_hot_area'
      };
      confApi.confInfo.r(postData).then(res => {
        this.hotFormDa = res.data;
      });
    },
    // 获取
    getList () {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then(res => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
