<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @row-save="handleSubmit"
      @row-update="rowUpdate"
      @refresh-change="getList"
      @row-del="rowDel"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >
      <template slot="menuLeft">
        <el-button icon="el-icon-sunny" type="warning" size="small" @click.stop="hotDialog">热门区域</el-button>
      </template>
    </avue-crud>
    <el-dialog
      title="热点区域"
      :visible.sync="dialogVisible"
      width="60%">
      <avue-form
        ref="form"
        :option="hotFormOp"
        v-model="hotFormDa"
        @submit="submitHotForm">
      </avue-form>
    </el-dialog>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
