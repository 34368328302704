<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="tbleOption"
      @row-save="handleSubmit"
      @row-update="rowUpdate"
      @refresh-change="getList"
      @row-del="rowDel"
      @on-load="onLoad"
      :search.sync="searchData"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
      v-model="member"
    >
      <template slot="menuLeft">
        <el-button
          icon="el-icon-s-promotion"
          type="success"
          size="small"
          @click.stop="sendTplMsgDialog"
          >发送微信模板消息</el-button
        >
      </template>
      <template slot="is_subscribe" slot-scope="{ row }">
        <el-tag size="mini" type="success" v-if="row.is_subscribe">已订阅</el-tag>
        <el-tag size="mini" type="danger" v-else>未订阅</el-tag>
      </template>
      <template slot-scope="{ row, size, disabled }" slot="referrerForm">
        <el-select
          v-model="member.referrer"
          filterable
          remote
          :disabled="disabled"
          placeholder="请输入关键词"
          @visible-change="memberChangeSelect"
          :size="size"
          :remote-method="getMember"
        >
          <el-option
            v-for="item in memberList"
            :key="item.id"
            :label="item.nickname"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </template>
      <template slot-scope="{ row, disabled, size }" slot="referrerSearch">
        <avue-input-table
          :size="size"
          :props="referrerInputTabel.props"
          :column="referrerInputTabel.column"
          :formatter="referrerFormatter"
          :on-load="referrerOnLoad"
          v-model="searchData.referrer"
          placeholder="请选择推荐人"
        ></avue-input-table>
      </template>
      <template slot="datetimeSearch" slot-scope="{ row, size }">
        <el-date-picker
          v-model="searchData.datetime"
          :size="size"
          type="daterange"
          unlink-panels
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </template>
      <template slot="searchMenu" slot-scope="{ row, size }">
        <el-button icon="el-icon-download" :size="size" @click="searchExcel()">导出</el-button>
      </template>
      <template slot-scope="{ type, size, row, index }" slot="menu">
        <el-button icon="el-icon-edit" :size="size" :type="type" @click.stop="rowEdit(row, index)"
          >编辑</el-button
        >
        <el-button icon="el-icon-view" :size="size" :type="type" @click.stop="rowView(row, index)"
          >查看</el-button
        >
        <el-button icon="el-icon-delete" :size="size" :type="type" @click.stop="rowDel(row, index)"
          >停用</el-button
        >
      </template>
    </avue-crud>
    <!--用户编辑-->
    <el-dialog
      title="用户编辑"
      size="70%"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="dialogCloseCallback"
    >
      <avue-form ref="form" v-model="formDa" :option="formOp" @submit="rowUpdate">
        <template slot-scope="{ row, size, disabled }" slot="referrer">
          <el-select
            v-model="formDa.referrer"
            filterable
            remote
            :disabled="disabled"
            placeholder="请输入关键词"
            @visible-change="memberChangeSelect"
            :size="size"
            :remote-method="getMember"
          >
            <el-option
              v-for="item in memberList"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
      </avue-form>
    </el-dialog>
    <!--发送模板消息-->
    <el-dialog
      title="发送模板消息"
      size="50%"
      :close-on-click-modal="false"
      :visible.sync="sendTplDialogVisible"
      @close="dialogCloseCallback"
    >
      <avue-form ref="sendForm" v-model="formTplMsgDa" :option="formTplMsgOp" @submit="sendTplMsg">
      </avue-form>
    </el-dialog>
    <!---->
    <el-drawer
      title="会员详情"
      :before-close="drawerClose"
      :visible.sync="drawerVisible"
      direction="ttb"
      size="100%"
    >
      <div class="details">
        <!--用户详情-->
        <div class="details-header">
          <div class="details-title">基础信息</div>
        </div>
        <div class="details-view">
          <div class="details-view-item">
            <div class="details-view-item-label">会员名称:</div>
            <div class="details-view-item-value">{{ member.nickname }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">手机号:</div>
            <div class="details-view-item-value">{{ member.mobile }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">openid:</div>
            <div class="details-view-item-value">{{ member.openid || "未取得" }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">身份证:</div>
            <div class="details-view-item-value">{{ member.idcard }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">所属分组:</div>
            <div class="details-view-item-value">{{ member.group }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">会员级别:</div>
            <div class="details-view-item-value">{{ member.rank }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">交易佣金:</div>
            <div class="details-view-item-value">￥{{ member.balance }}元</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">团队佣金:</div>
            <div class="details-view-item-value">￥{{ member.team_balance }}元</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">推荐人:</div>
            <div class="details-view-item-value">{{ member.referrer_text }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">状态:</div>
            <div class="details-view-item-value">{{ member.status_text }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">创建时间:</div>
            <div class="details-view-item-value">{{ member.create_time }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">更新日期:</div>
            <div class="details-view-item-value">{{ member.update_time }}</div>
          </div>
        </div>
        <div class="details-header">
          <div class="details-title">团队详情</div>
        </div>
        <!--团队详情-->
        <div class="details-view flex-column">
          <el-tabs v-model="activeName" @tab-click="handleTabClick">
            <el-tab-pane label="全部" name="1"></el-tab-pane>
            <el-tab-pane label="直系会员" name="2"></el-tab-pane>
            <el-tab-pane label="非直系会员" name="3"></el-tab-pane>
          </el-tabs>
          <avue-crud
            ref="child"
            :data="childData"
            :option="childOption"
            :page.sync="childPage"
            @on-load="getChildData"
            @refresh-change="getChildData"
          >
          </avue-crud>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
